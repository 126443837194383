
	frappe.templates['file_control'] = `<label for="{{ file.fieldname }}" class="font-weight-bold">{{ file.label }}</label>
<input id="{{ file.fieldname }}" type="hidden" name="{{ file.fieldname }}" value="{{ file.value ||"" }}">
<div class="input-group" data-fieldname="{{ file.fieldname }}">
    <div class="input-group-prepend clear-file" {% if (!file.value) { %}style="display: none;" {% } %}>
        <button type="button" class="input-group-text font-weight-bold">&times;</button>
    </div>

    <div class="custom-file">
        <input id="{{ file.fieldname }}_file" name="{{ file.fieldname }}_file" type="file" class="custom-file-input" accept=".pdf, .jpeg, .jpg, .png,"
        {% if (file.value) { %}style="display: none;" {% } %}>
        <label class="custom-file-label text-muted" for="{{ file.fieldname }}_file">
            {% if (file.value) { %}
                <a href="{{ file.file_url }}" target="_blank">{{ file.file_name }}</a>
            {% } else { %}
                Attach file
            {% } %}
        </label>
    </div>
</div>

<script>
$("[data-fieldname={{file.fieldname}}] .clear-file").click(function () {
    $(".custom-file-label[for={{ file.fieldname }}_file]").html("Attach File");
    $("#{{ file.fieldname }}").val("");
    $("#{{ file.fieldname }}_file").val("");
    $(this).hide({easing: "swing"});
});

$("#{{ file.fieldname }}_file").on("change", function () {
    $("[data-fieldname={{file.fieldname}}] .clear-file").toggle(!!this.files.length);
    $(".custom-file-label[for={{ file.fieldname }}_file]").html(this.files.length ? this.files[0].name : "Attach File");
    $("#{{ file.fieldname }}").val("");
  });
</script>
`;
