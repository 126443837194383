frappe.provide("fs");

let stripe_card_map = {
  amex: "American Express",
  diners: "Diners Club",
  discover: "Discover",
  jcb: "JCB",
  mastercard: "MasterCard",
  unionpay: "UnionPay",
  visa: "Visa",
  unknown: "Unknown",
};

const specific_users = [
  { email: "ai@filtersource.com", fullname: "Petra AI", abbr: "AI" },
  {
    email: "website@filtersource.com",
    fullname: "Filtersource.com API",
    abbr: "WEB",
  },
];

specific_users.forEach((user) => {
  if (!frappe.boot.user_info[user.email]) {
    frappe.boot.user_info[user.email] = {
      email: user.email,
      fullname: user.fullname,
      image: null,
      name: user.email,
      abbr: user.abbr,
    };
  }
});

fs.get_payment_method_label = (pm) => {
  // pm will either have a card or us_bank_account property
  if (pm.card) {
    const funding = pm.card.funding !== "unknown" ? pm.card.funding + " " : "";

    return `${
      stripe_card_map[pm.card.brand] || pm.card.brand
    } ${funding}card ending ${pm.card.last4} (exp. ${pm.card.exp_month}/${
      pm.card.exp_year
    })`;
  } else if (pm.us_bank_account) {
    return `${pm.us_bank_account.bank_name} ending ${pm.us_bank_account.last4}`;
  }
};

fs.get_ticket_preview = (ticket) => {
  frappe.call({
    args: {
      ticket: ticket,
    },
    method: "petra.api.zendesk.get_ticket",
    callback: function (r) {
      if (!r.exc) {
        let dialog = new frappe.ui.Dialog({
          title: `Preview Ticket Comments`,
          fields: [
            {
              fieldname: "comments",
              fieldtype: "HTML",
              options: r.message.template,
            },
          ],
          primary_action_label: `Open <strong>#${ticket}</strong> in Zendesk`,
          primary_action: function () {
            window.open(
              "https://filtersource.zendesk.com/agent/tickets/" + ticket
            );
            dialog.hide();
          },
        });
        dialog.show();
      } else {
        frappe.throw(
          `<center>Failed to find ticket #${ticket}.<br>Are you sure the number is correct?</center>`
        );
      }
    },
  });
};

fs.generate_sales_context = (frm) => {
  let progress = 0;
  const progressInterval = setInterval(() => {
    progress += 10;
    frappe.show_progress(__("Generating Sales Context"), progress, 100);
    if (progress >= 90) clearInterval(progressInterval);
  }, 1000);

  frappe.call({
    method: "petra.api.open_ai.sales_tools.generate_sales_context_trigger",
    args: { doctype: frm.doc.doctype, docname: frm.doc.name },
    callback: function (r) {
      clearInterval(progressInterval);
      frappe.hide_progress();
      if (r.message && r.message.success) {
        frappe.show_alert({
          message: __("Sales Context generated successfully"),
          indicator: "green",
        });
        frm.reload_doc();
      } else {
        frappe.msgprint({
          title: __("Sales Context Generation Failed"),
          indicator: "red",
          message:
            (r.message && r.message.message) ||
            __("An error occurred while generating Sales Context"),
        });
      }
    },
    error: function () {
      clearInterval(progressInterval);
      frappe.hide_progress();
      frappe.msgprint({
        title: __("Sales Context Generation Failed"),
        indicator: "red",
        message: __(
          "An unexpected error occurred. Please try again or contact support."
        ),
      });
    },
  });
};

fs.formatZendeskTickets = function (content) {
  if (!content) return content;

  // Regex to match both "#123456" and "ticket 123456" formats
  const ticketRegex = /#(\d{6})|ticket\s+(\d{6})/i;

  if (!ticketRegex.test(content)) return content;

  return content.replace(
    /#(\d{6})|ticket\s+(\d{6})/gi,
    function (match, hashNumber, wordNumber) {
      const ticketNumber = hashNumber || wordNumber;
      return (
        '<a href="#" title="Preview Ticket #' +
        ticketNumber +
        '" onclick="fs.get_ticket_preview(\'' +
        ticketNumber +
        "'); return false;\">" +
        match +
        "</a>"
      );
    }
  );
};

fs.formatPetraDocs = function (content) {
  if (!content) return content;

  const docRegex = /(SQ|SO|PO|INV)-(\d{6})(?:-(\d+))?/gi;

  if (!docRegex.test(content)) return content;

  const docTypeMap = {
    SQ: "Quotation",
    SO: "Sales Order",
    PO: "Purchase Order",
    INV: "Sales Invoice",
  };

  return content.replace(
    docRegex,
    function (match, docType, docNumber, amendment) {
      const fullDocType = docTypeMap[docType.toUpperCase()];
      const fullDocName = amendment
        ? `${docType}-${docNumber}-${amendment}`
        : `${docType}-${docNumber}`;
      return (
        `<a href="#" title="View ${fullDocType} ${fullDocName}" ` +
        `onclick="frappe.set_route('Form', '${fullDocType}', '${fullDocName}'); return false;">` +
        `${match}</a>`
      );
    }
  );
};

fs.formatPetraDocsAndTickets = function (content) {
  return fs.formatZendeskTickets(fs.formatPetraDocs(content));
};

frappe.ui.form.AssignmentDialog = class PetraAssignmentDialog extends (
  frappe.ui.form.AssignmentDialog
) {
  constructor(opts) {
    super(opts);
  }

  get_assignment_row(assignment) {
    let row = super.get_assignment_row(assignment);
    let btn_group = row.find(".btn-group");

    btn_group.prepend(`
            <button type="button" class="btn btn-default view-todo-btn" title="${__(
              "View Todo"
            )}">
                ${frappe.utils.icon("search", "xs")}
            </button>
        `);

    btn_group.find(".view-todo-btn").click(() => {
      this.view_todo(assignment);
    });

    return row;
  }

  view_todo(assignment) {
    frappe.db
      .get_list("ToDo", {
        filters: {
          reference_type: this.frm.doctype,
          reference_name: this.frm.docname,
          allocated_to: assignment,
        },
        fields: ["name"],
        limit: 1,
      })
      .then((todos) => {
        if (todos.length > 0) {
          frappe.set_route("Form", "ToDo", todos[0].name);
        } else {
          frappe.msgprint(__("No ToDo found for this assignment."));
        }
      });
  }
};

frappe.provide("frappe.search");

const original_get_global_results = frappe.search.utils.get_global_results;

// Override the get_global_results function to prioritize Customer and Supplier results
frappe.search.utils.get_global_results = function (
  keywords,
  start,
  limit,
  doctype = ""
) {
  return original_get_global_results
    .call(this, keywords, start, limit, doctype)
    .then((results_sets) => {
      const priorityDoctypes = ["Customer", "Supplier"];
      const prioritySets = [];
      const otherSets = [];

      results_sets.forEach((set) => {
        if (priorityDoctypes.includes(set.title)) {
          prioritySets.push(set);
        } else {
          otherSets.push(set);
        }
      });

      return [...prioritySets, ...otherSets];
    });
};
